<template>
  <nav-bar v-if="$route.name !== 'User'" />
  <div class="contain-router-view">
    <router-view />
  </div>
  <common-footer></common-footer>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import CommonFooter from "@/components/common/Footer.vue";

// import { reactive, ref } from '@vue/reactivity'
// import { onMounted, watch } from '@vue/runtime-core'

export default {
  name: "app",
  components: {
    NavBar,
    CommonFooter,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped lang="less">
@import url("assets/css/base.css");
  /* 设置 router-view 区域的样式，包括间距 */
  .contain-router-view {
    flex: 1; /* 填充剩余空间 */
    padding: 20px; /* 例如设置上下左右各20像素的内边距 */
  }
  
  /* 固定 common-footer 到页面底部 */
  common-footer {
    margin-top: auto;
  }
</style>
