import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";

const Home = () => import("@/views/home/Home.vue");
const Market = () => import("@/views/market/index.vue");
const MarketDetails = () => import("@/views/market/details.vue");
const Trends = () => import("@/views/trends/index.vue");
const Assets = () => import("@/views/assets/index.vue");
const User = () => import("@/views/user/index.vue");
const Guild = () => import("@/views/guild/guild.vue");
const CreateGuild = () => import("@/views/createdGuild/createdGuild.vue");
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/user",
  },
  {
    path: "/user",
    name: "User",
    component: User,
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/market",
    name: "Market",
    component: Market,
  },
  {
    path: "/market-details",
    name: "MarketDetails",
    component: MarketDetails,
  },
  {
    path: "/trends",
    name: "Trends",
    component: Trends,
  },
  {
    path: "/assets",
    name: "Assets",
    component: Assets,
  },
  {
    path:'/guild',
    name:'Guild',
    component:Guild
  },
  {
    path: "/createGuild",
    name: "CreateGuild",
    component: CreateGuild,
  },
  // 替代vue2中的'*'通配符路径
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let userId = sessionStorage.getItem("userId");
  if (to.path !== "/user" && !userId) {
    next("/user");
    return;
  }
  next();
});
export default router;
