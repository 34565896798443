import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入element-plus
import ElementPlus from 'element-plus'
import dayjs from 'dayjs';

const app = createApp(App)

import 'element-plus/dist/index.css'

// 全局使用element-icon
import * as ElIcons from '@element-plus/icons-vue'
for (const name in ElIcons){
    app.component(name,(ElIcons as any)[name])
}
// 将 dayjs 注入到 Vue 原型中
// 将 dayjs 注入到 Vue 的全局属性中
app.config.globalProperties.$dayjs = dayjs;
app.use(store).use(router).use(ElementPlus).mount('#app')
