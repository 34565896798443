import { createStore } from 'vuex'

export default createStore({
  state: {
    userId: sessionStorage.getItem('userId') || '',
    userInfo: {}
  },
  getters: {
  },
  mutations: {
    setUserId(state, id) {
      state.userId = id;
      sessionStorage.setItem('userId', id)
    },
    setUserInfo(state, id) { }
  },
})
