<template>
    <div class="footer">© 2024 . All rights reserved</div>
</template>
<style scoped lang="less">
.footer {
    text-align: center;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    border-top: 1px solid #ECEEED;

}
</style>