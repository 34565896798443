<template>
    <div class="navbar">
        <div class="navbar-contain">
            <div class="logo">
                <img src="@/assets/img/logo.svg" alt="">
            </div>

            <el-menu class="navbar-list" mode="horizontal" :default-active="defaultActive" :ellipsis="false">
                <!-- <el-menu-item index="1">
                    <div class="navbar-list-item">
                        <img src="@/assets/img/navIcon/Home.svg" alt="">
                        <div>Home</div>
                    </div>
                </el-menu-item> -->
                <el-menu-item index="2">
                    <div class="navbar-list-item" @click="toLink('/market')">
                        <img src="@/assets/img/navIcon/Market.svg" alt="">
                        <div>Market</div>
                    </div>
                </el-menu-item>
                <!-- <el-menu-item index="3">
                    <div class="navbar-list-item">
                        <img src="@/assets/img/navIcon/PublishKeys.svg" alt="">
                        <div>Publish keys</div>
                    </div>
                </el-menu-item> -->
                <el-menu-item index="4" @click="toLink('/trends')">
                    <div class="navbar-list-item">
                        <img src="@/assets/img/navIcon/Trends.svg" alt="">
                        <div>Trends</div>
                    </div>
                </el-menu-item>
                <el-menu-item index="5" @click="toLink('/assets')">
                    <div class="navbar-list-item">
                        <img src="@/assets/img/navIcon/Assets.svg" alt="">
                        <div>Assets&Rewards</div>
                    </div>
                </el-menu-item>
                <el-menu-item index="6" @click="toLink('/guild')">
                    <div class="navbar-list-item">
                        <img src="@/assets/img/navIcon/Assets.svg" alt="">
                        <div>Guild</div>
                    </div>
                </el-menu-item>
            </el-menu>

            <div class="top-btn">
                <div>
                    <el-avatar :style="getStyle(0)" :size="24" :src="require('@/assets/img/logo.jpg')" />
                    <el-avatar :style="getStyle(1)" :size="24" :src="require('@/assets/img/assets/defaultAvatar.svg')" />
                    <!-- <el-avatar :style="getStyle(2)" :size="24" src="@/assets/img/logo.jpg" /> -->
                </div>
                <div style="margin: 0 6px; color:black;font-size: 13px">
                    USD
                </div>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        $SOL
                        <el-icon class="el-icon--right">
                            <arrow-down />
                        </el-icon>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="toLink('/createGuild')">Create Guild</el-dropdown-item>
                            <!-- <el-dropdown-item>User 2</el-dropdown-item>
                            <el-dropdown-item>User 3</el-dropdown-item> -->
                            <!-- <el-dropdown-item disabled>Action 4</el-dropdown-item>
                            <el-dropdown-item divided>Action 5</el-dropdown-item> -->
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>

            <div class="login-btn" @click="toLink('/user')">
                <!-- <span class="btn-text">Wallet Login</span> -->
                 <img src="@/assets/img/Login.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, computed, watchEffect, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'


export default {
    name: "NavBar",
    setup() {
        const router = useRouter()
        const route = useRoute()
        const { ctx } = getCurrentInstance()

        const defaultActive = computed(() => {
            // 匹配路由name
            let indexMap = {
                1: ['home'],
                2: ['Market', 'MarketDetails'],
                4: ['Trends'],
                5: ['Assets'],
                6: ['Guild'],
            }
            let activeIndex = '1'
            for (const key in indexMap) {
                let list = indexMap[key]
                if (list.indexOf(route.name) > -1) {
                    activeIndex = key
                }
            }
            return activeIndex
        })
        
        watch(() => ctx.$root.$route.path,
            (new1, old) => {
                console.log(new1, old)
            })

        const toLink = (path) => {
            router.push({ path })
        }
        const getStyle = (index) => {
            return {
                transform: `translateX(${index * -6}px)`,
            }
        }
        return {
            toLink,
            defaultActive,
            getStyle
        }
    },
}
</script>
<style scoped lang="less">
.el-dropdown-link{
    color:#7A8A99;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    .el-icon--right{
        margin-left: 8px;
    }
}
.navbar {
    box-sizing: initial;
    width: 100%;
    height: 70px;
    background: #fff;
    border-bottom: 1px solid #D9DEDC;
    padding: 16px 0;

    .navbar-contain {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: var(--contain-width);
        height: 100%;
        margin: auto;

        .logo img {
            display: block;
            width: 66px;
            height: 66px;
        }

        .navbar-list {
            display: flex;
            height: 46px;

            .navbar-list-item {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 94px;

                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                }
            }
        }

        .top-btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .login-btn {
            cursor: pointer;
            // position: relative;
            // border-radius: 58px;
            // padding: 14px 21px;
            // overflow: hidden;
            // background: linear-gradient(-90deg, #FAFEFF 0%, #E8F3FE 100%);
            // border: none;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     border-radius: 58px;
            //     /* 确保伪元素的圆角与按钮一致 */
            //     padding: 3px;
            //     /* 增加 padding 值以加厚边框 */
            //     background: linear-gradient(to right, #3A8AFE, #00DEFF);
            //     /* 更鲜明的渐变色 */
            //     -webkit-mask:
            //         linear-gradient(#fff 0 0) content-box,
            //         linear-gradient(#fff 0 0);
            //     -webkit-mask-composite: destination-out;
            //     mask-composite: exclude;
            //     z-index: -1;
            //     /* 确保伪元素位于按钮下方 */
            // }

            // .btn-text {
            //     background-image: linear-gradient(to right, #3A8AFE, #00DEFF);
            //     -webkit-background-clip: text;
            //     background-clip: text;
            //     color: transparent;
            // }
        }

    }

}
</style>

<style>
.navbar .el-menu--horizontal {
    border-bottom-color: transparent;
    --el-menu-active-color: #000;
}

/* .navbar .el-menu--horizontal .el-menu{
    border-bottom: none !important
} */
</style>